import React from "react";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import {
	DocPageMeta,
	InlineMessage,
	ContentSection,
	HeaderSection,
	Code,
	Header,
} from "../../../../../src/docs";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";

export const Meta: DocPageMeta = {
	category: "Tables and lists",
	title: "Table configs",
	notepad: "https://hackmd.io/mD10lQNmSZqpn82HkooTAg",
};

export default (): JSX.Element => (
	<>
		<HeaderSection title="Column configs" subTitle="Helper methods to configure tables." />
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				{/* <ImportExample lab component={ListTable} /> */}
				<Header.H2>Examples</Header.H2>
				<Paragraph>
					These helper methods are generating objects for common types and patterns that contain
					properties such as header and render to describe the column. The objects they return can
					then be used in an array that's passed to the <Code>Table</Code> component as the{" "}
					<Code>columns</Code> prop.
				</Paragraph>
				<Paragraph>There are pre-defined column configs for the following types:</Paragraph>
				<Ul
					items={[
						"String",
						"Mapped string",
						"Number",
						"Mapped number",
						"Mapped percentage",
						"Date",
						"Datetime",
						"Button",
						"Button link",
						"Expandable",
					]}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Ul items={["...", "..."]} />
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul items={["...", "..."]} />
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<Paragraph>
						This component comes with built-in accessibility, no extra work required.
					</Paragraph>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<Ul items={["...", "..."]} />
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);
